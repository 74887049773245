import React from 'react';
import { isHauptadmin } from '../lib/globalData';
import NoPagination from '../lib/NoPagination';
import { List, Filter, Create, Edit, Datagrid, SimpleForm, TextField, DateField, ReferenceField, SelectField,
  EditButton, TextInput, DateInput, SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';

  const statusChoices = [
    { id: 'active', name: 'Aktiv' },
    { id: 'renewed', name: 'Erneuert' },
    { id: 'cancelled', name: 'Gelöscht' },
 ]
 
 const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput source="q" label="Suchen" alwaysOn resettable/>
    <ReferenceInput source="id_user" reference="user" sort={{ field: 'email', order: 'ASC' }} alwaysOn resettable>
      <AutocompleteInput optionText="email" label="Benuzter" inputValueMatcher={() => null} />
    </ReferenceInput>
    <ReferenceInput source="id_parent" reference="user" sort={{ field: 'email', order: 'ASC' }} alwaysOn resettable>
      <AutocompleteInput optionText="email" label="Team" inputValueMatcher={() => null} />
    </ReferenceInput>
    <SelectInput source="status" label="Status" choices={statusChoices} alwaysOn resettable/>
    <DateInput source="expiry" alwaysOn resettable/>
  </Filter>
);

const ListForm = (props) => {
  return (
    <List {...props} bulkActionButtons={false} title="Lizenzen" 
      exporter={isHauptadmin() && props.exporter} >
      <Datagrid style={{ width: 'auto' }}>
        <ReferenceField label="Benutzer" source="id_user" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="Team" source="id_parent" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <SelectField source="status" label="Status" choices={statusChoices} />
        <DateField source="expiry" label="Ablauf" />
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm {...props}> 
      <SelectInput source="status" label="Status" choices={statusChoices} alwaysOn />
      <ReferenceInput source="id_user" reference="user" sort={{ field: 'email', order: 'ASC' }} alwaysOn >
        <AutocompleteInput optionText="email" label="Benuzter" inputValueMatcher={() => null} fullWidth />
      </ReferenceInput>
      <ReferenceInput source="id_parent" reference="user" sort={{ field: 'email', order: 'ASC' }} alwaysOn >
        <AutocompleteInput optionText="email" label="Team" inputValueMatcher={() => null} fullWidth />
      </ReferenceInput>
      <DateInput source="expiry" label="Datum" />
    </SimpleForm>
  )
};

const page =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Statische Seite erstellen">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Statische Seite bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default page