import React, { useState } from 'react';
import { isHauptadmin } from '../lib/globalData';
import Move from '../lib/Move';
import NoPagination from '../lib/NoPagination';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, NumberField, SelectField, 
  EditButton, TextInput, SelectInput } from 'react-admin';

const sectionChoices = [
   { id: 'header', name: 'Kopf' },
   { id: 'footer', name: 'Fuss' },
]

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
    <List {...props} bulkActionButtons={false} title="Statische Seiten" sort={{ field: 'pos', order: 'ASC' }} perPage={1000}
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />}>
      <Datagrid style={{ width: 'auto' }}>
        <SelectField source="section" label="Bereich" choices={sectionChoices} />
        <TextField source="title" label="Titel" />
        <Move label="Move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="page" />
    {isHauptadmin() &&
        <NumberField source="pos" label="Position"/>
    }
    {isHauptadmin() &&
        <NumberField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm {...props}> 
        <SelectInput source="section" label="Bereich" choices={sectionChoices} />
        <TextInput source="title" label="Titel" />
    </SimpleForm>
  )
};

const page =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Statische Seite erstellen">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Statische Seite bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default page