import React from 'react';
import { isHauptadmin } from '../lib/globalData';
import NoPagination from '../lib/NoPagination';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, DateField , 
  EditButton, TextInput, DateInput } from 'react-admin';

const ListForm = (props) => {
  return (
    <List {...props} bulkActionButtons={false} title="Statische Seiten" sort={{ field: 'datum', order: 'DESC' }} perPage={1000}
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />}>
      <Datagrid style={{ width: 'auto' }}>
        <DateField source="datum" label="Datum" />
        <TextField source="place" label="Ort" />
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm {...props}> 
        <DateInput source="datum" label="Datum" />
        <TextInput source="place" label="Ort" fullWidth/>
        <TextInput source="text" label="Text" fullWidth multiline/>
        <TextInput source="link" label="Link" fullWidth />
    </SimpleForm>
  )
};

const page =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Statische Seite erstellen">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Statische Seite bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default page