import React from 'react';
import { isHauptadmin, formatImg, getGlobalData } from '../lib/globalData';
import { apiPath } from '../lib/isTest';
import MyPagination from '../lib/MyPagination';
import { List, Create, Edit, Datagrid, SimpleForm, FormDataConsumer, DeleteButton, Button, 
  TextField, NumberField, SelectField, ReferenceArrayField, ReferenceManyField, SingleFieldList, ChipField, FileField, BooleanField,
  EditButton, TextInput, NumberInput, ImageInput, FileInput, ReferenceInput, AutocompleteInput, ReferenceArrayInput, SelectArrayInput, SelectInput, BooleanInput,
  Filter,  useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import MuiButton from '@mui/material/Button';
import TextileInput from '../lib/TextileInput';

const priceTypeChoices = [
   { id: 'demand', name: 'On Demand' },
   { id: 'home', name: '@Home' },
   { id: 'phys', name: 'Physich' },
   { id: 'license', name: 'Lizenz' },
   { id: 'free', name: 'Free' },
];

const statusChoices = [
   { id: 'active', name: 'Aktiv' },
   { id: 'inactive', name: 'Inaktiv' },
];

const ListForm = (props) => {
  return (
    <List {...props} pagination={<MyPagination />} perPage={50} bulkActionButtons={false} title="Statische Seiten" sort={{ field: 'title', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter}>
      <Datagrid style={{ width: 'auto' }}>
        <TextField source="title" label="Titel" />
        <TextField source="claim" label="Claim" />
        <TextField source="claim" label="Untertitel" />
        <ReferenceArrayField label="Kategorien" source="categories" reference="category">
            <SingleFieldList>
                <ChipField source="name" variant="outlined" color="primary" size="small" />
            </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="popular" label="Bel."/>
        <NumberField source="tiptoi_id" label="Tiptoi" />
        <NumberField source="numstart" label="Start" />
        <BooleanField source="hoerprojekt" label="HP" FalseIcon="null" />
    {isHauptadmin() &&
        <NumberField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const MediaField = props => {    
  var url = (props.record && props.record.src)?props.record.src:props.record;
  if (url.url) {
    url = url.url;
  }
  if (!url) {
    url = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs';
  }
  if (props.title==='Video') {
    return (
      <video controls style={{maxWidth:"480px"}}>
        <source src={url} type="video/mp4"></source>
      </video> 
    );
  }
  else {
    return (<img src={url} style={{width:'300px'}} alt=""/>);
  }
}

const AddPrice = ({ classes }) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Button
      variant="contained"
      component={Link}
      to={{
        pathname: "/price/create",
        search: `?id_product=${record.id}`,
      }}
      label="Neuer Preis"
      title="Neuer Preis erstellen"
    >
    </Button>
  );
}

const LinkIDs = props => (
  <div style={{display:'flex', gap:'2em'}}>
    <NumberInput source="id_project" label="Projekt" />
    <NumberInput source="tiptoi_id" />
    <NumberInput source="numstart" label="Anzahl Start Sticker"  />
    <NumberInput source="minpos" />
    <NumberInput source="maxpos" />
    <SelectInput source="prodstatus" label="Status" choices={statusChoices} />
    <BooleanInput source="hoerprojekt" label="Hörprojekt" />
  </div>
)

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const token = getGlobalData('token');
  const buildQR = () => {
  }

  return (
    <SimpleForm {...props}> 
        <LinkIDs />
        <TextInput source="title" label="Titel" fullWidth />
        <TextInput source="claim" label="Claim" fullWidth />
        <div style={{display:'flex', gap:'2em'}}>
          <NumberInput source="popular" label="Beliebtheit"/>
          <TextInput source="qrcode" label="QR-Code"/>
          <FormDataConsumer>
    {({ formData, ...rest }) => {
      if (!formData.qrcode) return null;
      return (    
            <div>
              <MuiButton href={apiPath+'qrcode/'+formData.id+'?code='+formData.qrcode+'&token='+token} target="_blank" variant="contained" style={{marginTop:'1em'}}>QR-Codes erstellen</MuiButton>
            </div>
      )
    }}
          </FormDataConsumer>
        </div>
        <ImageInput source="video" accept="video/mp4" label="Video">
          <MediaField source="src" title="Video" />
        </ImageInput>
        <ImageInput source="image" accept="image/*" label="Bild">
          <MediaField source="src" title="Bild" />
        </ImageInput>
        <FormDataConsumer>
    {({ formData, ...rest }) => 
          <TextileInput source="description" label="Beschreibung" formData={formData} multiline fullWidth/>
    }
        </FormDataConsumer>
        <ReferenceArrayInput label="Kategorien" source="categories" reference="category"
           perPage={1000} sort={{ field: 'name', order: 'ASC' }}>
            <SelectArrayInput optionText="name" variant="outlined" color="primary" size="small" />
        </ReferenceArrayInput>
        
        <ReferenceManyField label="Preise" reference="price" target="id_product" sort={{ field: 'type', order: 'ASC' }} fullWidth>
            <Datagrid>
                <SelectField source="type" label="Typ" choices={priceTypeChoices} />
                <TextField source="text" />
                <TextField source="price" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </ReferenceManyField>
        <AddPrice />
        <FileInput source="docs" accept="application/pdf" format={formatImg} label="PDF's" multiple={true}>
          <FileField source="url" title="name" target="_blank" />
        </FileInput>
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="title" label="Titel" alwaysOn />
        <ReferenceInput source="id_category" reference="category" sort={{ field: 'pos', order: 'ASC' }} alwaysOn >
          <AutocompleteInput optionText="name" inputValueMatcher={() => null} />
        </ReferenceInput>
        <SelectInput source="prodstatus" label="Status" choices={statusChoices} alwaysOn/>
    </Filter>
);

const product =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Statische Seite erstellen">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Statische Seite bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default product