import React, { useState } from 'react';
import { isHauptadmin, formatImg } from '../lib/globalData';
import Move from '../lib/Move';
import MyPagination from '../lib/MyPagination';
import { List, Create, Edit, Datagrid, SimpleForm, FormDataConsumer, TextField, NumberField, SelectField, ImageField, 
  EditButton, TextInput, SelectInput, ReferenceInput, AutocompleteInput, ImageInput, Filter } from 'react-admin';
import TextileInput from '../lib/TextileInput';

const typeChoices = [
   { id: 'image', name: 'Bild' },
   { id: 'video', name: 'Video' },
   { id: 'slider', name: 'Slider' },
]
  
const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
    <List {...props} pagination={<MyPagination />} perPage={50} bulkActionButtons={false} title="Statische Seite" sort={{ field: 'pos', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter} >
      <Datagrid style={{ width: 'auto' }}>
        <TextField source="data.title" label="Titel" />
        <SelectField source="type" label="Typ" choices={typeChoices} />
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="staticel" />
    {isHauptadmin() &&
        <NumberField source="pos" label="Position"/>
    }
    {isHauptadmin() &&
        <NumberField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm {...props}> 
        <SelectInput source="type" label="Typ" choices={typeChoices} />
          <TextInput source="data.title" label="Titel" />
          <TextInput source="data.claim" label="Claim" />
        <FormDataConsumer>
    {({ formData, ...rest }) => 
          <React.Fragment>
            <TextileInput source="data.text" label="Text" source1="data" source2="text" formData={formData} multiline fullWidth />
      {formData.type==='slider' && 
          <TextInput source="data.link" label="Link" />
      }
      {(formData.type==='slider') && 
            <React.Fragment>
              <ImageField source="data.imageurl" label="Bild" />
              <ImageInput source="image" accept="image/*" format={formatImg} label="Bild">
                <ImageField source="src" title="image" />
              </ImageInput>
            </React.Fragment>
      }
      {(formData.type==='image') && 
            <React.Fragment>
              <ImageField source="data.imageurl" label="Bild" />
              <ImageInput source="image" accept="image/*" format={formatImg} label="Bild">
                <ImageField source="src" title="image" />
              </ImageInput>
              <ImageInput source="video" accept="video/mp4" format={formatImg} label="Video">
                <ImageField source="src" title="video" />
              </ImageInput>
            </React.Fragment>
      }
          </React.Fragment>
    }
        </FormDataConsumer>
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
      <ReferenceInput source="id_page" reference="page" sort={{ field: 'pos', order: 'ASC' }}alwaysOn >
        <AutocompleteInput optionText="title" inputValueMatcher={() => null} />
      </ReferenceInput>
    </Filter>
);

const staticel =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Statische Inhalte erstellen">
      <SimpleForm {...props}> 
          <ReferenceInput source="id_page" reference="page" sort={{ field: 'pos', order: 'ASC' }}alwaysOn >
            <AutocompleteInput optionText="title" inputValueMatcher={() => null} />
          </ReferenceInput>
          <SelectInput source="type" label="Typ" choices={typeChoices} />
          <TextInput source="data.title" label="Titel" />
      </SimpleForm>
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Statische Inhalte bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default staticel