import React from 'react';
import { isHauptadmin, getGlobalData } from '../lib/globalData';
import { apiPath } from '../lib/isTest';
import { List, Create, Edit, Datagrid, SimpleForm, Filter, Pagination, useListContext,
  TopToolbar, Button, 
  ReferenceField, TextField, SelectField, NumberField, 
  TextInput, EditButton, CreateButton, ReferenceInput, SelectInput, NumberInput, AutocompleteInput, BooleanInput, DateTimeInput } from 'react-admin';
import PrintIcon from '@mui/icons-material/Print';

const statusChoices = [
   { id: 'open', name: 'offen' },
   { id: 'paid', name: 'bezahlt' },
]

const payChoices = [
   { id: 'open', name: 'offen' },
   { id: 'card', name: 'Karte' },
   { id: 'post', name: 'Post' },
   { id: 'admin', name: 'Admin' },
]

const token = getGlobalData('token');

const monate = [];
const today = new Date();
for (var y = today.getFullYear(); y>=2022; y--) {
  for (var m = 12; m>0; m--) {
    const m2 = String(m).padStart(2, '0')
    monate.push ({id:y+'-'+m2, name:y+'-'+m2});
  }
}

const ListFilter = (props) => (
    <Filter {...props}>
      <ReferenceInput source="id_user" reference="user" sort={{ field: 'email', order: 'ASC' }} alwaysOn >
        <AutocompleteInput optionText="email" label="Benuzter" inputValueMatcher={() => null} />
      </ReferenceInput>
          <SelectInput source="status" label="Status" choices={statusChoices} alwaysOn />
      <AutocompleteInput source="monat" choices={monate} label="Monat" alwaysOn />
      <BooleanInput source="free" label="Gratis" alwaysOn />
    </Filter>
);

  const ListActions = () => {
    const { filterValues } = useListContext();  
    return (
      <TopToolbar>
        <CreateButton label="Einzelrechnung" />
        <Button href={apiPath+"invoice/pdf?token="+token+"&monat="+filterValues.monat} target="_blank"><PrintIcon />&nbsp;PDFs erstellen</Button>
      </TopToolbar>
    )
  };


const ListForm = (props) => {
  const InvoiceList = () => {
    
    return (
      <Datagrid style={{ width: 'auto' }}>
        <TextField source="id" label="#" />
        <TextField source="timestamp" label="Gekauft" />
        <ReferenceField label="Benutzer" source="id_user" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <SelectField source="status" label="Status" choices={statusChoices} />
        <SelectField source="paytype" label="Zahlart" choices={payChoices} />
        <TextField source="paytime" label="Bezahlt" />
        <NumberField source="amount" label="Betrag" options={{ style: 'currency', currency: 'CHF' }} />
    {isHauptadmin() &&
        <TextField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    )
  }
  
  return (
    <List {...props} actions={<ListActions/>} title="Rechnungen" sort={{ field: 'timestamp', order: 'ASC' }} perPage={50} pagination={<Pagination rowsPerPageOptions={[50, 100, 200]} />} >
      <InvoiceList />
    </List>
  )
}

const invoice =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />}/>
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Einzelrechnung erstellen" redirect="list">
      <SimpleForm {...props}> 
          <ReferenceInput source="id_user" reference="user" sort={{ field: 'email', order: 'ASC' }} >
            <AutocompleteInput optionText="email" inputValueMatcher={() => null} label="Benutzer" fullWidth />
          </ReferenceInput>
          <TextInput source="rgtext" label="Text" multiline fullWidth />
          <TextInput source="rganz" label="Anzahl" defaultValue="1" />
          <TextInput source="rgprice" label="Preis" />
      </SimpleForm>
    </Create>
  ),

  edit: ({ classes, ...props }) => {
    return (
      <Edit title="Preis bearbeiten" mutationMode="optimistic" {...props}>
        <SimpleForm>
          <ReferenceInput source="id_user" reference="user" sort={{ field: 'email', order: 'ASC' }}alwaysOn >
            <AutocompleteInput optionText="email" inputValueMatcher={() => null} label="Benutzer" />
          </ReferenceInput>
          <SelectInput source="status" label="Status" choices={statusChoices} />
          <SelectInput source="paytype" label="Zahlart" choices={payChoices} />
          <DateTimeInput source="paytime" label="Bezahlt" />
          <NumberInput source="amount" label="Betrag" />
        </SimpleForm>
      </Edit>
    )
}
}   
export default invoice