const isTest = window.location.hostname.indexOf('dev.soundolino') > -1;
var apiPath = 'https://api.soundolino.ch/';
if (window.location.hostname.indexOf('bern.soundolino')>-1) {
  apiPath = 'https://api.bern.soundolino.ch/';
}
if (isTest) {
  apiPath = 'https://api.dev.soundolino.ch/';
}
export { apiPath };

export default isTest;
