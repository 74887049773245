import React from 'react';
import { Admin, Resource } from 'react-admin';
import isTest from './lib/isTest';
import dataProvider from './lib/dataProvider';
import authProvider from './lib/authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from 'ra-language-german';
import { Layout, AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';

import user from './routes/user'
import page from './routes/page'
import product from './routes/product'
import category from './routes/category'
import staticel from './routes/staticel'
import price from './routes/price'
import invoice from './routes/invoice'
import deliver from './routes/deliver'
import workshop from './routes/workshop'
import license from './routes/license'

import './App.css';

const MyAppBar = props => {
  return (
    <AppBar {...props} style={{backgroundColor:isTest?"#DC3545":""}} >
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        style={{flex:1}}
      />
    </AppBar>
  );
};

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

const i18nProvider = polyglotI18nProvider(() => germanMessages, 'de');
const App = () => (
    <Admin
      layout={MyLayout}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource name="page" {...page} options={{label:"Statische Seiten"}} />
      <Resource name="staticel" {...staticel} options={{label:"Statische Inhalte"}} />
      <Resource name="category" {...category} options={{label:"Kategorie"}} />
      <Resource name="product" {...product} options={{label:"Produkte"}} />
      <Resource name="price" {...price}  options={{label:"Preise"}} />
      <Resource name="user" {...user} options={{label:"Benutzer"}} />
      <Resource name="invoice" {...invoice} options={{label:"Rechnungen"}} />
      <Resource name="asset" {...deliver} options={{label:"Versand"}} />
      <Resource name="workshop" {...workshop} options={{label:"Worshops"}} />
      <Resource name="license_a" {...license} options={{label:"Lizenzen"}} />
    </Admin>
    );
export default App;
