import React from 'react';
import { isHauptadmin } from '../lib/globalData';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, SelectField,
  EditButton, TextInput, SelectInput, ReferenceInput, AutocompleteInput, useCreateController } from 'react-admin';
import { parse } from "query-string";

const priceTypeChoices = [
   { id: 'demand', name: 'On Demand' },
   { id: 'home', name: '@Home' },
   { id: 'phys', name: 'Physich' },
   { id: 'license', name: 'Lizenz' },
   { id: 'free', name: 'Free' },
]

const ListForm = (props) => {
  return (
    <List {...props} bulkActionButtons={false} title="price" sort={{ field: 'type', order: 'ASC' }} >
      <Datagrid style={{ width: 'auto' }}>
        <SelectField source="type" label="Typ" choices={priceTypeChoices} />
        <TextField source="text" />
        <TextField source="price" />
    {isHauptadmin() &&
        <TextField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const PriceCreate = props => {
    const hash = window.location.hash.split("?")    
    const search = decodeURIComponent(hash[1]);
    const { id_product: prod_string } = parse(search);
    const id_product = prod_string ? parseInt(prod_string, 10) : "";
    const redirect = "/product/"+id_product;
    return (
      <Create title="Preis hinzufügen..." redirect={redirect} {...props}>
        <SimpleForm defaultValues={{ id_product: id_product }}>
          <ReferenceInput label="Produkt" source="id_product" reference="product" sort={{ field: 'title', order: 'ASC' }} >
            <AutocompleteInput optionText="title" style={{width:'400px'}}  />
          </ReferenceInput>
          <SelectInput source="type" label="Typ" choices={priceTypeChoices} />
          <TextInput source="text" />
          <TextInput source="price" />
        </SimpleForm>
      </Create>
  )
}

const price =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: PriceCreate,
  
  edit: ({ classes, ...props }) => {
    const redirect = (basePath, id, data) => `/product/${data.id_product}`;
    return (
      <Edit title="Preis bearbeiten" redirect={redirect} mutationMode="optimistic" {...props}>
        <SimpleForm >
          <SelectInput source="type" label="Typ" choices={priceTypeChoices} />
          <TextInput source="text" />
          <TextInput source="price" />
        </SimpleForm>
      </Edit>
    )
}
}   
export default price