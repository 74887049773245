import React, { useState } from 'react';
import { isHauptadmin } from '../lib/globalData';
import Move from '../lib/Move';
import MyPagination from '../lib/MyPagination';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, NumberField, 
  EditButton, TextInput, Filter } from 'react-admin';

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
    <List {...props} pagination={<MyPagination />} bulkActionButtons={false} title="Statische Seiten" sort={{ field: 'pos', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter}>
      <Datagrid style={{ width: 'auto' }}>
        <TextField source="name" label="Name" />
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="category" />
    {isHauptadmin() &&
        <NumberField source="pos" label="Position"/>
    }
    {isHauptadmin() &&
        <NumberField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm {...props}> 
        <TextInput source="name" label="Name" />
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
    </Filter>
);

const category =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Statische Seite erstellen">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Statische Seite bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default category