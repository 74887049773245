import { TextInput } from 'react-admin';
import Grid from '@mui/material/Grid';

const TextileInput = props => {
  const {formData, ...rest} = props;
  if (!formData) return null;
  var t = '';
  if (props.source2) {
    t = formData[props.source1] && formData[props.source1][props.source2]?formData[props.source1][props.source2]:'';
  }
  else {
    t = formData[props.source]?formData[props.source]:'';
  }
  return (
  <Grid container>
    <Grid item xs={12} md={5}>
      <TextInput {...rest} multiline fullWidth />
    </Grid>
    <Grid item xs={0} md={1}>
    </Grid>
    <Grid item xs={12} md={5}>
      <div dangerouslySetInnerHTML={{ __html: window.textile(t) }} />
    </Grid>
  </Grid>
  )
}
export default TextileInput