const setGlobalData = data => {
  if (!window.globalData) {
    window.globalData = {};
  }
  window.globalData = {...window.globalData, ...data};
}

const getGlobalData = key => {
  if (!window.globalData) {
    window.globalData = {};
  }
  if (!window.globalData[key]) {
    var data = {};
    if (key==='token') {
      data = localStorage.getItem('token');
      setGlobalData({token:data});
      return data;
    }
    if (key==='info') {
      data = JSON.parse(localStorage.getItem('info'));
      setGlobalData({info:data});
      return data;
    }
    return {}
  }
  return window.globalData[key];
}

const getInfoValue = key => {
  const info = getGlobalData('info');
  if(!info[key]) {
    return false;
  }
  return info[key];
}

const getRoute = () => {
  var r = window.location.hash;
  while (r[0]==='#' || r[0]==='/') {
    r = r.substr(1)
  }
  return r;
}

const isHauptadmin = () => {
  const info = getGlobalData('info');
  return info && info.id_parent===-2;
}

const textileConvert = (text) => {
  var t = window.textile(text);
  t = t.replace(/<a href=/g, '<a target="_blank" href=');
  return t;
};

function formatImg(value) {
  if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it 
   return { url: value };
  } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

function getRecht(perm, route) {
  if (perm) {
    for (var i = 0; i<perm.length; i++) {
      if (perm[i].route === route) {
        return perm[i].recht;
      }
    }
  }
  return -1;
}

export { setGlobalData, getGlobalData, getInfoValue, getRoute, isHauptadmin, textileConvert, formatImg, getRecht };
