import React from 'react';
import { getGlobalData } from '../lib/globalData';
import { apiPath } from '../lib/isTest';
import MyPagination from '../lib/MyPagination';
import { List, Datagrid, Filter, Button, 
  TextField, 
  SelectInput, NumberInput,
  useUpdateMany, useRefresh, useUnselectAll, useRecordContext} from 'react-admin';
import IconPrint from '@mui/icons-material/Print';
import IconDrafts from '@mui/icons-material/Drafts';
import IconSend from '@mui/icons-material/Send';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

const deliverChoices = [
   { id: 'no', name: '-' },
   { id: 'notpaid', name: 'nicht bezahlt' },
   { id: 'prepare', name: 'zum versenden' },
   { id: 'sent', name: 'Versandt' },
]
const token = getGlobalData('token');

const Print = (props) => {
  const record = useRecordContext();
  if (!record.id_project) return  null;
  const pen = record.pen.split('/');  
  return (
    <Button href={apiPath+"output/"+record.id_project+"?adm=1&token="+token+"&size="+pen[1]+"&type="+pen[0]+"&mode=1122&num="+record.count} target="_blank"><IconPrint /></Button>
  )
}

const Lieferschein = ({barcode}) => {
  const record = useRecordContext();
  return (
    <Button href={apiPath+"invoice/"+record.id_invoice+"?token="+token+"&qr=3"+(barcode?'&bc=1':'')} target="_blank">{barcode?<ViewWeekIcon />:<IconDrafts />}</Button>
  )  
}

const ListFilter = (props) => (
    <Filter {...props}>
      <SelectInput source="deliver" label="Status" choices={deliverChoices} alwaysOn />
      <NumberInput source="id_invoice" label="RG" alwaysOn />
    </Filter>
);

const BulkSent = ({ selectedIds }) => {
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('asset');
    const [updateMany, { isLoading }] = useUpdateMany();
    
    const versandt = () => {
      updateMany('asset', { ids: selectedIds, data: { sent: 1 } },
        { onSuccess: () => { refresh(); unselectAll(); },}
      );
    }

    return (
      <Button label="Versandt" disabled={isLoading} onClick={versandt} >
        <IconSend />
      </Button>
    );
};

const ListForm = (props) => {
  return (
    <List {...props} pagination={<MyPagination />} perPage={50} bulkActionButtons={<BulkSent />} title="Versand" sort={{ field: 'id', order: 'ASC' }} >
      <Datagrid style={{ width: 'auto' }}>
        <TextField source="id_invoice" label="RG" />
        <TextField source="text" label="Titel" />
        <TextField source="pen" label="Stift" />
        <Button label="" />
        <Print />
        <Lieferschein />
        <Lieferschein barcode={true} />
      </Datagrid>
    </List>
  )
}

const deliver =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />}/>
  ),
}   
export default deliver