import React, { useState } from 'react';
import { isHauptadmin, getGlobalData } from '../lib/globalData';
import dataProvider from '../lib/dataProvider';
import { apiPath } from '../lib/isTest';
import { useRecordContext, List, Create, Edit, Datagrid, SimpleForm, TextField, 
  EditButton, TextInput, SelectArrayInput, Filter } from 'react-admin';
import Button from '@mui/material/Button';
  
const rightsChoices = [
  {id:'Dev', name:'Entwickler'},
  {id:'admin', name:'Administrator'},
  {id:'invoice', name:'Rechnungsempfänger'},
  {id:'project', name:'Projekt'},
]  
  
const ListForm = (props) => {
  return (
  <>
    <List {...props} bulkActionButtons={false} title="User" sort={{ field: 'email', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter}>
      <Datagrid style={{ width: 'auto' }}>
        <TextField source="email" />
        <TextField source="institution" />
        <TextField source="firstname" />
        <TextField source="name" />
        <TextField source="address" />
        <TextField source="zip" />
        <TextField source="city" />
        <TextField source="country" />
        <TextField source="flat_license" label="Pauschallizenz" />
{/*        <NumberField source="teamsize"/>*/}
    {isHauptadmin() &&
        <TextField source="id" align="right" />
    }
        <EditButton label="" />
      </Datagrid>
    </List>
    
    <div>
      <Button variant="contained" href={apiPath+"user/export?token="+getGlobalData('token')} target="_blank">Adressen exportieren</Button>
    </div>
  </>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const [flatChange, setFlatChange] = useState({id:0, del:[], add:[]});
  const rec = useRecordContext();
  const validateFlat = async (value ) => {
    dataProvider.update('user/checkflat', { id:rec.id, data: { old: rec.flat_license, new : value} }).then(response => {
      setFlatChange(response.data);
    });
  }

  return (
    <SimpleForm {...props} mode="onBlur" > 
      <SelectArrayInput source="rights" choices={rightsChoices} fullWidth />
      <TextInput source="email" />
      <TextInput source="institution" />
      <TextInput source="firstname" />
      <TextInput source="name" />
      <TextInput source="address" />
      <TextInput source="zip" />
      <TextInput source="city" />
      <TextInput source="country" />
      <TextInput source="flat_license" label="Pauschallizenz" validate={validateFlat} fullWidth helperText={false}/>
      <div style={{color:'#d32f2f'}}>
  {flatChange.del && flatChange.del.map(el=>(
        <span>{el.email}, </span>
  ))}
      </div>
      <div style={{color:'#1b8e20'}}>
  {flatChange.add && flatChange.add.map(el=>(
        <span>{el.email}, </span>
  ))}
      </div>
      <TextInput source="password" />
{/*      <NumberInput source="teamsize"/> */}
</SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="email" alwaysOn />
    </Filter>
);

const User =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="Benutzer erstellen">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="Benutzer bearbeiten" mutationMode="optimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default User